<template>
    <div>
        <report-window
            v-if="ready"
            endpoint="/reports/agencies_hotelcost"
            :perPage="500"
            :fields="fields"
            :title="title"
            :headers="headers"
            :headerColumns="headerColumns"
            :templates="templates"
            :hideFilters="options && options.hideFilters"
            :runMounted="options && options.runMounted"
            :cardView="options && options.cardView"
            :reportViewClass="options? options.reportViewClass: null"
            :colsView="options? options.colsView: null"
            :current.sync="current"
            :slotsFields="[]"
            :notFilterable="options && options.notFilterable"
            :updateReport="updateReport"
            :afterRender="afterRender"
            :columnClick="columnClick"
            :afterRun="afterRun"
            :emb="options? options.emb: false"
            :columnsClasses="columnsClasses"
            :headerSticky="true"
            :beforeRender="beforeRender"
            :cellClasses="cellClasses"
            :hideSearch="true"
            :hideDashboard="true"
            :hideToggle="true"
            :hideConfig="true"
            :hideCSV="true"
            :disableSort= "true"
            :simpleTable="true"
            @afterEdit="afterEdit"
            fileName="Hotel Rates"
            ref="report"
            :pdf="true"
            :columnStyles="columnStyles"
            :headerMesssage="headerMesssage"
            tableWidth="auto"
        ></report-window>
        <hotel-modal
            v-if="hotel"
            :hotel.sync="hotel"
        ></hotel-modal>
    </div>
</template>

<script>
import '@/css/agencies.scss';
import ReportWindow from "@/components/tools/ReportWindow";
const hotelModal = importVueComp('components/reports', 'HotelModal');
export default {
    name: "hotel-cost-list-report",
    props: ["options", "reportData"],
    components: {
        ReportWindow,
        'hotel-modal': hotelModal
    },
    computed: {
    },
    mounted () {
        frontTools.setReportValues(this);
        this.ready = true;
    },
    data() {
        let self = this;
        return {
            current: {BreakPeriods: true, FilterDate: '0'},
            ready: false,
            hColumns: ['HotelName', 'HotelComment', 'StartDate', 'EndDate', 'Room', 'Amount', 'CurrencyId', 'NetBy'],
            headerColumns: null,
            //headerColumns: null,
            hotel: null,
            updateReport: false,
            columnsClasses: {
                Amount: 'text-right',
                HotelName : 'cursor-pointer break-wrap',
                HotelComment: 'mx-width-200 break-wrap'
            },
            columnStyles: {
                0: { cellWidth: 43}, 
                1: { cellWidth: 62}, 
                2: { cellWidth: 22}, 
                3: { cellWidth: 22},
                4: { cellWidth: 25},
                5: { cellWidth: 15},
                6: { cellWidth: 16},
                7: { cellWidth: 43},
                8: { cellWidth: 20},
            },
            fields: [
                {
                    name: "CountryId",
                    relation: "country",
                    optionLabels: "Name",
                    editor: 'select',
                    label: "Country",
                    sort: true,
                },
                {
                    name: "CityId",
                    relation: "city",
                    optionLabels: "Name",
                    editor: 'vue-select',
                    label: "City",
                    required: true,
                    sort: true,
                    filters: (row) => {
                        if (!self.current.CountryId) return true;
                        if (row.CountryId==self.current.CountryId) return true;
                    }
                },
                {
                    name: "HotelCategoryId",
                    relation: "hotelcategory",
                    optionLabels: "Name",
                    addBlank: true,
                    editor: 'select',
                    label: "Category",
                    sort: true,
                },
                {
                    name: "HotelId",
                    relation: "hotel",
                    optionLabels: "Name",
                    editor: 'vue-select',
                    label: "Hotel",
                    addBlank: true,
                    sort: true,
                    filters: (row) => {
                        if (self.current.HotelCategoryId) {
                            if (self.current.HotelCategoryId != row.HotelCategoryId) return false;
                        }
                        if (!self.current.CityId) return true;
                        if (row.CityId == self.current.CityId) return true;
                    },
                    endpoint: '/api/hotel/get_list_by_date_city_id'
                },
                {
                    name: "TransDate",
                    editor: "date",
                    label: "Date"
                },
            ],
            title: "Hotel Rates",
            headers: {
                HotelName: `${tr('Hotel')} (${tr('Category')})`,
                CityName: "City",
                RoomType: "Room",
                RoomCategory: "Category",
                CurrencyId: "Currency",
                NetBy: "Rates Per",
                Amount: 'Price',
                StartDate: 'Valid From',
                EndDate: 'Valid To',
                HotelComment: 'Notes'
            },
            cellClasses: {
                StartDate: [{
                    class: 'not-visible',
                    condition: row => row.notVisible,
                }],
                EndDate: [{
                    class: 'not-visible',
                    condition: row => row.notVisible,
                }],
                HotelName: [{
                    class: 'not-visible',
                    condition: row => row.notVisible2,
                }],
                HotelComment: [{
                    class: 'not-visible',
                    condition: row => row.notVisible2,
                }]
            },
            columnClick: {
                HotelName: this.showHotel,
            },
            templates: [
                {
                    name: "Room",
                    callback: (row) => {
                        return `${row.RoomType}, ${row.RoomCategory}`
                    }
                },
                {
                    name: "HotelName",
                    callback: (row) => {
                        if (!row.HotelCategory) return row.HotelName;
                        return `${row.HotelName} (${row.HotelCategory})`
                    }
                },
                {
                    name: "StartDate",
                    callback: (row) => {
                        if (!row.StartDate) return "";
                        return moment(row.StartDate).format("DD/MM/YYYY");
                    }
                },
                {
                    name: "EndDate",
                    callback: (row) => {
                        if (!row.EndDate) return "";
                        return moment(row.EndDate).format("DD/MM/YYYY");
                    }
                },
                {name: 'Amount', callback: (row) => {
                        return tools.toNumber(row.Amount, 0, row.CurrencyId);
                    }
                },
                {
                    name: "NetBy",
                    callback: (row) => {
                        return tr(row.NetBy);
                    }
                },

                {
                    name: "Days",
                    callback: (row) => {
                        if (!row.Days) return "";
                        let w = row.Days.split(',');
                        let a = [];
                        for (let d of w) {
                            a.push(tr(d));
                        }
                        return a.join(',');
                    }
                },
            ],
            headerMesssage: {
                colspan: 8,
                html: false,
                rows: [
                    { text: "Rates per room, per night.", class: "white-space-nowrap" },
                    { text: "Not valid for National Holidays, Winter Holidays, Carnival, Easter, Christmas and New Year.", class: "white-space-nowrap"},
                    { text: "Please ask in each case the policies to be applied for each hotel.", class: "white-space-nowrap"},
                ]
            }
        };
    },
    watch: {
        options: {
            handler () {
                frontTools.setReportValues(this);
                this.updateReport = !this.updateReport;
            },
            deep: true,
        },
    },
    methods: {
        afterRun (report) {
            this.$emit("update:reportData", report.dataList);
        },
        afterRender (report) {
            /*for (let i in report.list) {
                let row = report.list[i];
                row.rowSpan = {};
                if (!row.notVisible) {
                    let rowSpan = _.filter(report.list, (r) => {
                        return r.HotelId == row.HotelId && r.StartDate == row.StartDate && r.EndDate == row.EndDate;
                    }).length;
                    row.rowSpan.StartDate = rowSpan;
                    row.rowSpan.EndDate = rowSpan;
                    let k = parseInt(i) + 1;
                    let div = $('#' + k + '-' + 'StartDate')[0];
                    if (div) {
                        $(div.parentElement).attr('rowspan', rowSpan)
                    }
                    let div2 = $('#' + k + '-' + 'EndDate')[0];
                    if (div2) {
                        $(div2.parentElement).attr('rowspan', rowSpan)
                    }
                }
                if (!row.notVisible2) {
                    let rowSpan = _.filter(report.list, (r) => {
                        return r.HotelId == row.HotelId;
                    }).length;
                    row.rowSpan.HotelName = rowSpan;
                    let k = parseInt(i) + 1;
                    let div = $('#' + k + '-' + 'HotelName')[0];
                    if (div) {
                        $(div.parentElement).attr('rowspan', rowSpan)
                    }
                }
            }*/
        },

        async afterEdit (fieldName) {
            this.$refs.report.fieldOptions = await tools.calculateFieldOptions(this.$refs.report.filterFields);
            if (fieldName == 'CountryId') {
                this.current.CityId = null;
                this.current.HotelId = null;
                this.current = Object.assign({}, this.current);
            }
            if (fieldName == 'CityId') {
                this.current.HotelId = null;
                this.current = Object.assign({}, this.current);
            }
        },
        async beforeRender (report) {
            this.headerColumns = Object.assign([], this.hColumns);
            for (let column of report.serverColumnsHeader) {
                if (column == 'Days' && this.headerColumns.indexOf('Days') == -1) {
                    this.headerColumns.push('Days')
                }
            }
            this.headerColumns = Object.assign([], this.headerColumns);
            for (let i in report.list) {
                let row = report.list[i];
                row.rowSpan = {};
                if (!row.notVisible) {
                    let rowSpan = _.filter(report.list, (r) => {
                        return r.HotelId == row.HotelId && r.StartDate == row.StartDate && r.EndDate == row.EndDate;
                    }).length;
                    row.rowSpan.StartDate = rowSpan;
                    row.rowSpan.EndDate = rowSpan;
                }
                if (!row.notVisible2) {
                    let rowSpan = _.filter(report.list, (r) => {
                        return r.HotelId == row.HotelId;
                    }).length;
                    row.rowSpan.HotelName = rowSpan;
                    row.rowSpan.HotelComment = rowSpan;
                }
            }
            if (this.current.CityId) {
                let city = await api.get('/api/city/' + this.current.CityId)
                if (city && city.HotelMessage && city.HotelMessage.length > 20) {
                    this.headerMesssage = Object.assign({}, {
                        html: city.HotelMessage,
                        colspan: 8,
                    });
                }
            }


        },
        async showHotel (row, report) {
            let h = await api.get('/api/hotel/' + row.HotelId);
            if (h) {
                this.hotel = h;
            }
        },

    }
};
</script>
